<template>
  <div style="background: #e35049;color: #000;height: 100%;">
    <van-nav-bar title="我的拓客券" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div class="hed_lis">
      <div class="hhcc" @click="qiehuan(0)" :class="active == '0'?'acitv':''">可售</div>
      <div class="hhcc" @click="qiehuan(1)" :class="active == '1'?'acitv':''">已售</div>
      <div class="hhcc" @click="qiehuan(2)" :class="active == '2'?'acitv':''">已过期</div>
    </div>
    <div class="main" style="background: #e35049;">
      <div class="list" v-for="(item,idx) in form.getList" :key="idx" v-if="active == '0'">
        <img class="yh_img" src="../../assets/img/tkbeijin.png" />
        <div class="list_a">
          <div class="left letzuo" v-if="item.type != 5">
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 1">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.discountPrice}}</span>
            </div>
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 2">
              <span style="font-size: 27px;">{{item.discountPrice}}折</span>
            </div>
            <div style="line-height: 20px;margin-top:5px;">
              <!-- {{item.type == 1?'代金券':'折扣券'}} -->
              <span v-if="item.type == 1">代金券</span>
              <span v-if="item.type == 2">折扣券</span>
            </div>
          </div>
          <div class="left letzuo" v-else>
            <div style="margin-top: 40px;font-weight: bold;">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.sellPrice}}</span>
            </div>
            <div style="line-height: 35rpx;margin-top:10rpx;">售价</div>
          </div>
          <div class="right ris">
            <div class="ris_a">
              <span class="left h1">{{item.couponName}}</span>

              <span class="right cunsts" v-if="item.sellRule == 2">零售</span>
              <span
                class="right cunsts"
                v-if="item.sellRule == 1"
                style="color: #0d906e;border: 2px solid #0d906e;"
              >转赠</span>
            </div>
            <div
              class="ris_a fsz"
              style="margin-top: 15px;color: #656565;"
            >有效期:{{item.startDate}}~{{item.validityDate}}</div>
            <div class="ris_a fsz" style="margin-top: 0px;">
              <span class="left" @click="tixiangqin(item)">查看详情</span>
            </div>
            <!-- <div class="ris_a fsz" style="margin-top: 5rpx;" wx:if="{{status==4}}">
            <span class="left">查看详情</span>

            <span class="right ylinqu">已领取</span>
            <div class="right" style="margin-right: 20rpx;">
              <image class="tox" src="{{item.giftAvatar}}">
              </image>
              <span style="vertical-align: middle;">{{item.giftName}}</span>
            </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="list" v-for="(item,idx) in form.useList" :key="idx" v-if="active == '1'">
        <img class="yh_img" src="../../assets/img/tkbeijin.png" />
        <img class="tupiao" src="../../assets/img/shouchu.png" alt />
        <div class="list_a">
          <div class="left letzuo" v-if="item.type != 5">
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 1">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.discountPrice}}</span>
            </div>
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 2">
              <span style="font-size: 27px;">{{item.discountPrice}}折</span>
            </div>
            <div style="line-height: 20px;margin-top:5px;">
              <span v-if="item.type == 1">代金券</span>
              <span v-if="item.type == 2">折扣券</span>
            </div>
          </div>
          <div class="left letzuo" v-else>
            <div style="margin-top: 40px;font-weight: bold;">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.sellPrice}}</span>
            </div>
            <div style="line-height: 35rpx;margin-top:10rpx;">售价</div>
          </div>
          <div class="right ris">
            <div class="ris_a">
              <span class="left h1">{{item.couponName}}</span>
              <span class="right" v-if="active == '0'">
                <span class="cunsts" v-if="item.sellRule == 2">零售</span>
                <span
                  class="cunsts"
                  v-if="item.sellRule == 1"
                  style="color: #0d906e;border: 2px solid #0d906e;"
                >转赠</span>
              </span>
            </div>
            <div
              class="ris_a fsz"
              style="margin-top: 15px;color: #656565;"
            >有效期:{{item.startDate}}~{{item.validityDate}}</div>
            <div class="ris_a fsz" style="margin-top: 0px;">
              <div class="right">
                <img class="ctouxiang" src="../../assets/img/cmendian.jpg" alt />
                <span style="vertical-align: middle;  font-weight: 600;">灰太狼</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list" v-for="(item,idx) in form.overdueList" :key="idx" v-if="active == '2'">
        <img class="yh_img" src="../../assets/img/tkbeijin.png" />
        <img class="tupiao" src="../../assets/img/guoqi.png" alt />
        <div class="list_a">
          <div class="left letzuo" v-if="item.type != 5">
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 1">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.discountPrice}}</span>
            </div>
            <div style="margin-top: 40px;font-weight: bold;" v-if="item.type == 2">
              <span style="font-size: 27px;">{{item.discountPrice}}折</span>
            </div>
            <div style="line-height: 20px;margin-top:5px;">
              <span v-if="item.type == 1">代金券</span>
              <span v-if="item.type == 2">折扣券</span>
            </div>
          </div>
          <div class="left letzuo" v-else>
            <div style="margin-top: 40px;font-weight: bold;">
              <span>￥</span>
              <span style="font-size: 27px;">{{item.sellPrice}}</span>
            </div>
            <div style="line-height: 35rpx;margin-top:10rpx;">售价</div>
          </div>
          <div class="right ris">
            <div class="ris_a">
              <span class="left h1">{{item.couponName}}</span>
              <span class="right" v-if="active == '0'">
                <span class="cunsts" v-if="item.sellRule == 2">零售</span>
                <span
                  class="cunsts"
                  v-if="item.sellRule == 1"
                  style="color: #0d906e;border: 2px solid #0d906e;"
                >转赠</span>
              </span>
            </div>
            <div
              class="ris_a fsz"
              style="margin-top: 15px;color: #656565;"
            >有效期:{{item.startDate}}~{{item.validityDate}}</div>
            <div class="ris_a fsz" style="margin-top: 0px;"></div>
          </div>
        </div>
      </div>
    </div>
    <van-popup show="show" bind:close="onClose">
      <canvas
        class="canvas-code"
        canvas-id="myQrcode"
        style="background:#fff;width: 180px;height: 180px;margin: auto;margin-top: 10px;"
      />
    </van-popup>
  </div>
</template>
<script>
import orderModel from "../../api/order";
export default {
  data() {
    return {
      show: false,
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      active: 0,
      form: {}
    };
  },
  created() {
    this.namelist();
    // this.onLoad()
  },
  methods: {
    tixiangqin(item) {
      this.$router.push({
        path: "tuoke_coupon_details",
        query: {
          couponNumber: item.couponNumber,
          couponName: item.couponName,
          startDate: item.startDate,
          validityDate: item.validityDate,
          sellPrice: item.sellPrice,
          sellRule:item.sellRule,
          uid:this.userInfo.id
        }
      });
    },
    namelist() {
      const data = {
        gid: this.shangjia.id,
        customerId: this.userInfo ? this.userInfo.customerId : ""
      };
      orderModel
        .tokercoupon(data)
        .then(e => {
          this.form = e.data;
        })
        .catch(() => loading.clear());
    },
    qiehuan(row) {
      this.active = row;
      // this.onLoad();
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="less" scoped>
.hed_lis {
  height: 45px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 99;
}
.hhcc {
  float: left;
  font-size: 14px;
  color: #646566;
  width: 25%;
  text-align: center;
  line-height: 45px;
  position: relative;
  overflow: hidden;
}
.van-popup {
  width: 200px;
  height: 200px;
}
.tox {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  margin-right: 7px;
  vertical-align: middle;
}

.van-row {
  text-align: center;
  background: #fff;
  color: #333;
  font-size: 14px;
  line-height: 44px;
  position: fixed;
  left: 0;
  top: 46px;
  width: 100%;
  z-index: 9;
}
.main {
  padding: 92px 10px 0 10px;
  overflow: hidden;
}
.list {
  /* height: 150rpx; */
  /* background: #fff; */
  margin-top: 10px;
  position: relative;
}
.list_a {
  overflow: hidden;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.ylinqu {
  color: #e35049;
  font-weight: bold;
  margin-top: 1px;
}
.tupiao {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -40px;
  width: 80px;
  margin-left: 15px;
  z-index: 20;
}
.letzuo {
  color: #e35049;
  /* margin-left: 60rpx; */
  text-align: center;
  font-size: 13px;
  width: 30%;
}
.yh_img {
  width: 100%;
  //   height: 1px;
}
.ris {
  padding: 23px 20px 10px 0px;
  width: 65%;
}
.ris_a {
  overflow: hidden;
}
.fsz {
  font-size: 12px;
  color: #919191;
}
.h1 {
  color: #000;
  font-size: 15px;
  font-weight: bold;
}
.cunsts {
  margin-top: 3px;
  color: red;
  border: 2px solid red;
  line-height: 16px;
  padding: 0 4px;
  border-radius: 6px;
  font-size: 12px;
  margin-right: 5px;
}

.cunts {
  color: #fff;
  background: #e35049;
  line-height: 20px;
  padding: 0 4px;
  border-radius: 7px;
  font-size: 11px;
}
.acitv {
  color: #e35049;
  font-weight: bold;
  font-size: 15px;
}
.ctouxiang {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  border-radius: 8px;
  margin-right: 6px;
}
</style>